var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardWidget',{attrs:{"widget":_vm.widget,"enabled":_vm.enabled,"cls":_vm.def.cls}},[_c('v-card',{staticClass:"my-0 mx-0 d-flex align-center",class:_vm.def.cls,staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-center pl-3"},[_c('v-col',{staticClass:"d-flex justify-center px-0"},[_c('v-fade-transition',{attrs:{"appear":"","group":"","leave-absolute":""}},[(_vm.iLoading)?_c('v-progress-circular',{key:"loading",ref:"transformer",attrs:{"indeterminate":_vm.iLoading,"color":_vm.iLoading2
								? 'primary'
								: (_vm.def.value / _vm.def.compareVal) * 100 - 100 > 0
								? 'success'
								: 'error',"value":_vm.iLoading2
								? 0
								: Math.abs((_vm.def.value / _vm.def.compareVal) * 100 - 100)}}):_c('v-progress-circular',{key:"data",ref:"transformer",attrs:{"indeterminate":_vm.iLoading,"color":_vm.iLoading2
								? 'primary'
								: _vm.def.value / _vm.def.compareVal > 1
								? 'success'
								: 'error',"value":_vm.iLoading2 ? 0 : (_vm.def.value / _vm.def.compareVal) * 100}})],1)],1),_c('v-col',{staticClass:"mx-2 align-center px-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"d-block text-truncate"},[_vm._v(_vm._s(_vm.def.title))]),_c('v-slide-y-reverse-transition',{attrs:{"group":"","leave-absolute":""}},[(!_vm.iLoading)?_c('div',{key:"real",staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.def.prefix)+" "+_vm._s(_vm.def.filter ? _vm.def.filter(_vm.def.value) : _vm.def.value)+_vm._s(_vm.def.postfix)+" ")]),(_vm.def.compareVal > 0 && _vm.def.value > 0)?_c('span',{staticClass:"ml-2 text-caption d-flex align-center",class:`${
								_vm.def.value / _vm.def.compareVal > 1 ? 'success' : 'error'
							}--text`},[_vm._v(" "+_vm._s(_vm._f("Percentage")((_vm.def.value / _vm.def.compareVal)))+" ")]):_vm._e()]):_c('div',{key:"fake",staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-h5"},[_vm._v(" ")])])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }