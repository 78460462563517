var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardWidget',{attrs:{"widget":_vm.widget,"enabled":_vm.enabled,"cls":_vm.def.cls}},[_c('v-card',{staticClass:"my-0 mx-0",class:_vm.def.cls,staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.def.title))]),(_vm.def.type == 'chart')?_c('chartLine',{staticStyle:{"position":"absolute","top":"0px","left":"0px","width":"100%","height":"100%"},attrs:{"data":_vm.def.data,"options":_vm.def.options}}):_vm._e(),_c('div',{staticClass:"mx-4 mt-7 mb-4 d-flex flex-row align-center"},[_c('v-progress-linear',{key:"data",ref:"transformer",attrs:{"indeterminate":_vm.iLoading,"rounded":"","height":"4","color":_vm.iLoading2
						? 'primary'
						: _vm.def.classFn
						? _vm.def.classFn(_vm.def.value)
						: _vm.def.value / _vm.def.compareVal > 1
						? 'success'
						: 'error',"value":_vm.iLoading2
						? 0
						: _vm.def.progressVal
						? _vm.def.progressVal(_vm.def.value, _vm.def.compareVal)
						: (_vm.def.value / _vm.def.compareVal) * 100}})],1),_c('v-slide-y-reverse-transition',[(!_vm.iLoading)?_c('v-card-text',{staticClass:"py-0 d-flex align-center"},[_c('span',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.def.filter ? _vm.def.filter(_vm.def.value) : _vm.def.value)+" ")]),(_vm.def.compareVal > 0 && _vm.def.value !== 0)?_c('span',{staticClass:"text-h7 ml-2",class:_vm.def.value / _vm.def.compareVal > 1 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm.def.differenceVal && _vm.def.differenceVal(_vm.def.value, _vm.def.compareVal) > 0 ? "+" : "")+" "+_vm._s(_vm._f("Percentage")(_vm.def.differenceVal ? _vm.def.differenceVal(_vm.def.value, _vm.def.compareVal) : (_vm.def.value / _vm.def.compareVal)))+" ")]):_vm._e()]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }