var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardWidget',{attrs:{"widget":_vm.widget,"enabled":_vm.enabled},on:{"widgetResize":_vm.onResize},scopedSlots:_vm._u([{key:"default",fn:function({ cls }){return [_c('v-card',{staticClass:"my-0 mx-0",class:cls,staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c('div',{style:(`height: 90%`)},[_c('v-card-title',{staticClass:"pt-0"},[(_vm.switcherTitle)?_c('v-switch',{staticClass:"mt-0",attrs:{"trueValue":1,"false-value":0,"hide-details":""},model:{value:(_vm.dataIndex),callback:function ($$v) {_vm.dataIndex=$$v},expression:"dataIndex"}}):_vm._e(),_c('div',{staticClass:"ml-2 mt-1"},[_vm._v(" "+_vm._s(_vm.switcherTitle ? _vm.def.title[_vm.dataIndex] : _vm.def.title)+" ")]),(_vm.showTypeSwitch)?[_c('v-spacer'),_c('v-sheet',{staticClass:"pa-1",attrs:{"width":90,"rounded":""}},[_c('div',{staticClass:"d-flex flex-row sliderBitch",style:(`background-position: ${_vm.sliderPos}; background-size: ${_vm.sliderSize}`)},[_c('div',{staticClass:"px-2 py-1",staticStyle:{"border-radius":"5px"}},[_c('v-icon',{staticClass:"noHover",attrs:{"color":`${
										_vm.chartType == 'chart-line'
											? 'kajot-anti-text'
											: 'kajot-text'
									}`},on:{"click":function($event){_vm.chartType = 'chart-line'}}},[_vm._v(" mdi-chart-line ")])],1),_c('div',{staticClass:"px-2 py-1",staticStyle:{"border-radius":"5px"}},[_c('v-icon',{staticClass:"noHover",attrs:{"color":`${
										_vm.chartType == 'chart-bar'
											? 'kajot-anti-text'
											: 'kajot-text'
									}`},on:{"click":function($event){_vm.chartType = 'chart-bar'}}},[_vm._v(" mdi-chart-bar ")])],1)])])]:_vm._e()],2),_c('v-fade-transition',{attrs:{"appear":"","leave-absolute":""}},[(_vm.loading)?_c('div',{staticClass:"pa-3",staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"loader",class:_vm.$vuetify.theme.dark ? 'dark' : 'light',staticStyle:{"height":"100%","width":"100%"}})]):_vm._e()]),(!_vm.loading)?_c('div',{staticClass:"d-flex flex-row align-center",staticStyle:{"height":"100%"}},[_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":_vm.showLegend ? 8 : 12}},[_c(_vm.chartType,{ref:"chart",tag:"Component",attrs:{"eager":"","data":_vm.graphData,"options":_vm.options,"plugins":_vm.chartPlugins}})],1),(_vm.showLegend)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.def.labels[_vm.dataIndex]),function(label,i){return _c('span',{key:i,staticClass:"mb-1",staticStyle:{"user-select":"none"},on:{"mouseenter":function($event){return _vm.triggerHover(i)},"mouseleave":function($event){return _vm.triggerHover(-1)}}},[_c('v-icon',{staticClass:"mr-2",style:(`color: ${_vm.getColor(i)} !important`)},[_vm._v(" "+_vm._s(label.icon)+" ")]),_c('span',[_vm._v(_vm._s(label.name))])],1)}),0)]):_vm._e()],1):_vm._e()],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }